const observers: Function[] = [];
let previousUrl: string = '';

const changeListener = () => {
    if (document.location.href !== previousUrl) {
        previousUrl = document.location.href;
        for (let observer of observers) {
            observer();
        }
    }
};

function initialize() {
    if (previousUrl !== '') {
        return;
    }

    previousUrl = document.location.href;

    let pushState = history.pushState;
    let replaceState = history.replaceState;

    history.pushState = (...params) => {
        pushState.apply(history, params);
        changeListener();
    };

    history.replaceState = (...params) => {
        replaceState.apply(history, params);
        changeListener();
    };

    window.addEventListener('popstate', () => changeListener());
}

export function addEventListener(callback: Function) {
    initialize();
    observers.push(callback);
}

export function removeEventListener(callback: Function) {
    for (let i in observers) {
        if (observers[i] === callback) {
            observers.splice(Number(i), 1);
            break;
        }
    }
}
