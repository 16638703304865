export type Company = {
    id: Number,
    name: string,
    city: string,
    phone_number: string,
    favicon: string,
    email_address: string,
    country: string
}

export function identify(trackerCode: string) {
    return new Promise<Company | null>(resolve => {
        let identify: Company | null = null;
        //do identify request
        const identifyRequest = new XMLHttpRequest();
        identifyRequest.open("GET", "https://api.leadinfo.com/v1/identify/" + trackerCode);
        identifyRequest.onreadystatechange = () => {
            if (identifyRequest.readyState !== identifyRequest.DONE) {
                return;
            }
            try {
                identify = JSON.parse(identifyRequest.responseText);
            } catch {
                //do nothing
            }
            resolve(identify);
        };
        identifyRequest.send();
    });
}
