import {BrowserPlugin, cookie} from "@snowplow/browser-tracker-core";
import {SelfDescribingJson} from "@snowplow/tracker-core";

interface EngageData {
    leclid?: string;

    [key: string]: string | undefined;
}

export const engage = {
    engage: function (): BrowserPlugin {
        return {
            contexts: () => {
                let data = [];
                let value = cookie('_hamawy');
                if (value) {
                    let event: SelfDescribingJson<EngageData> = {
                        schema: 'iglu:com.leadinfo/engage/jsonschema/1-0-0',
                        data: {
                            leclid: value,
                        },
                    };
                    data.push(event);
                }

                return data;
            },
        };
    }
}
