export function fingerPrint(): string {
    let input1 = window?.navigator?.userAgent
        + window?.navigator?.languages?.join(',')
        + window?.navigator?.vendor
        + window?.screen?.pixelDepth;
    let input2 = window?.screen?.colorDepth
        + window?.devicePixelRatio
        + (Math.abs(window.orientation) - 90 == 0 ? "landscape" : "portrait")
        + window?.screen?.availHeight
        + window?.screen?.availWidth;

    const cyrb53 = (str: string, seed = 0) => {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
        h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
        h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };

    let output1 = cyrb53(input1).toString(16)
    let output2 = cyrb53(input2).toString(16)

    let visitorId = String(output1 + output2).padEnd(32, '1f9d3c707d53f3d16c53dd73d70a6ce7596a9').substring(0, 32);

    //add date component
    let date = new Date();
    let month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
    let day = String(date.getDate()).length < 2 ? ('0' + date.getDate()) : date.getDate();
    let dateComponent = date.getFullYear() + '' + month + '' + day;

    return visitorId + '-' + dateComponent;
}
