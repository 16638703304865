import {Payload} from "@snowplow/tracker-core";
import {addEventListener} from "./urlChange";
import {BrowserPlugin} from "@snowplow/browser-tracker-core";

let urls: string[] = [];
let trackers: any[] = [];

const listener = () => {
    let currentUrl = document.location.href;
    urls.push(currentUrl);

    setTimeout(() => {
        if (urls.length > 0) {
            // no page view is tracked, do it our self
            for (let tracker of trackers) {
                tracker.trackPageView();
            }
        }
    }, 100);
};

export const singlePageApplicationPlugin = {
    spa: function (): BrowserPlugin {
        addEventListener(listener);
        return {
            activateBrowserPlugin: (tracker) => {
                trackers.push(tracker);
            },
            afterTrack: ((payload: Payload) => {
                    //check if it is a page view
                    if (payload.e !== 'pv') {
                        return;
                    }

                    //remove from url array
                    for (let i in urls) {
                        if (urls[i] === document.location.href) {
                            urls.splice(Number(i), 1);
                            break;
                        }
                    }
                }
            )
        }
    }
}
