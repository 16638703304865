import {BrowserPlugin, BrowserTracker} from "@snowplow/browser-tracker-core";
import {buildSelfDescribingEvent} from "@snowplow/tracker-core";

const sendEvent = (tracker: BrowserTracker, name: string, value: string) => {
    tracker.core.track(
        buildSelfDescribingEvent({
            event: {
                schema: 'iglu:com.leadinfo/cookie/jsonschema/1-0-0',
                data: {
                    name: name,
                    value: value,
                }
            }
        }),
    );
}

export const cookieStealerPlugin = {
    cs: function (): BrowserPlugin {
        return {
            activateBrowserPlugin: (tracker: BrowserTracker) => {
                // @ts-ignore
                let entries = document.cookie.split(';');

                for (let entry of entries) {
                    let parts = entry.split('=');

                    let name = String(parts.shift()).trim();
                    let value = String(parts.join('=')).trim();


                    switch (name) {
                        case 'hubspotutk':
                        case '__ss_tk':
                        case '_ga':
                            sendEvent(tracker, name, value);
                            break;
                        default:
                            if (name?.startsWith('_pk_id')) {
                                sendEvent(tracker, name, value);
                            }
                            break;
                    }
                }
            },
        }
    }
}
