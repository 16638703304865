export type Config = {
    [key: string]: {
        [key: string]: number | string | boolean
    }
}

export function loadConfig(trackerCode: string) {
    return new Promise<Config>(resolve => {
        //do config loading
        const http = new XMLHttpRequest();
        http.open("GET", 'https://collector.leadinfo.net/config/' + trackerCode + '/');

        http.onreadystatechange = () => {
            if (http.readyState !== http.DONE) {
                return;
            }

            //parse config
            let config = [];
            try {
                config = JSON.parse(http.responseText);
            } catch {
                //do nothing
            }

            //initialize config
            let final = {} as Config;

            //load retrieved config
            for (let item of config) {
                //set config
                final[item.key] = item.config;

                //only add script if present
                if (item.script != null) {
                    //create script element and attach
                    let element = document.createElement('script');
                    element.src = item.script;
                    let head = document.querySelector('head');
                    if (head) {
                        head.appendChild(element);
                    }
                }
            }
            resolve(final);
        };
        http.send();
    });
}
