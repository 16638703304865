export function enableFormTracking(queueFn: Function) {
    const config = {
        fields: {
            transform: (value: any) => {
                let v = String(value);
                //check if there is an @ and a . present
                if (v.indexOf('@') !== -1 && v.indexOf('.') !== -1) {
                    let re = /[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.][a-zA-Z]+/ig;
                    let ret = [];
                    let matches;
                    let i = 0;
                    //run regex to get matches
                    while (matches = re.exec(v)) {
                        //anonymize email
                        let split = matches[0].split('@');

                        let times = split[0].length;
                        //when 2 or 1 chars we anonymize it completely
                        if (times < 3) {
                            let str = "";
                            while (times > 0) {
                                str += 'x';
                                times--;
                            }
                            split[0] = str;
                        } else {
                            times -= 2;
                            let str = "";
                            while (times > 0) {
                                str += 'x';
                                times--;
                            }
                            split[0] = split[0].substr(0, 1) + str + split[0].substr(-1);
                        }

                        //yeah, save
                        ret.push(split.join('@'));

                        //prevent infinite loops
                        if (i === 100) {
                            break;
                        }
                        i++;
                    }
                    //return all anonymous email addresses
                    return ret.join(' ');
                }
                //all other data we are not interested in
                return null;
            }
        }
    };

    //make sure form tracking is done more consistently
    const persist = (intervalInSeconds: number) => {
        queueFn('enableFormTracking:cl1', config);
        setTimeout(persist, intervalInSeconds * 1000, intervalInSeconds << 1);
    }
    persist(2);
}
